import React, { useEffect } from "react";
import Loadable from 'react-loadable';
import './App.css';
import _http from './http'
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";

const TopUp = Loadable({
  loader: () => import(/* webpackChunkName: "topUp" */ './modules/topUp/index'),
  loading() {
    return <div>Loading...</div>
  }
});

const Membership = Loadable({
  loader: () => import(/* webpackChunkName: "membership" */ './modules/membership/index'),
  loading() {
    return <div>Loading...</div>
  }
});

const Agreement = Loadable({
  loader: () => import(/* webpackChunkName: "agreement" */ './modules/agreement/index'),
  loading() {
    return <div>Loading...</div>
  }
});

// const appid = 'wx019e8c363a5d8af6';

const BasicApp = () => {
  useEffect(() => {
    _http('/wechat/jsapi-conf', 'POST', {
      url: window.location.href.split('#')[0]
    }).then(res => {
      if (res.code === 0) {
        const {appid, timestamp, noncestr, signature} = res.data;
        window.wx.config({
          appId: appid, // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: noncestr, // 必填，生成签名的随机串
          signature, // 必填，签名，见附录1
          debug: false,
          jsApiList: ['chooseImage', 'previewImage'],
          openTagList: ['wx-open-launch-weapp']
        })
      }
    });

  }, [])
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <TopUp />
        </Route>
        <Route path="/memberShip">
          <Membership />
        </Route>
        <Route path="/agreement">
          <Agreement />
        </Route>
      </Switch>
    </Router>
  );
}

export default BasicApp;
