import { Toast } from "antd-mobile";

const prefix = '/wap/v1';
function requestData(url, method, data = {}, hideErrorToast = false) {
  // Default options are marked with *
  let options = {
    credentials: 'same-origin',
    headers: {
      'content-type': 'application/json'
    },
    method, // *GET, POST, PUT, DELETE, etc.
  }
  if (method.toLocaleUpperCase() === 'GET') {
    if (Object.keys(data).length > 0) {
      let paramsArray = [];
      //拼接参数
      Object.keys(data).forEach(key => paramsArray.push(key + '=' + data[key]))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }
  } else {
    Object.assign(options, {
      body: JSON.stringify(data), // must match 'Content-Type' header
    })
  }
  Toast.loading('Loading...', 100);
  return fetch(prefix + url, options)
    .then(response => {
      return response.json();
    }).then(res => {
      Toast.hide();
      if (res.code !== 0 && !hideErrorToast) {
        Toast.info(res.msg);
      }
      return res;
    }).catch(()=>{
      Toast.hide();
    })
}
export default requestData;
